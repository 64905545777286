import React from 'react';
import './App.css';  // This imports the CSS styles
import Stark from './Stark';  // This imports your custom component

function App() {
    return (
        <div className="App">
            <Stark />
        </div>
    );
}

export default App;

