import React from 'react';
import Particles from 'react-tsparticles';
import './App.css';

function Stark() {
    return (
        <div className="landing-page">
            <Particles
                id="tsparticles"
                options={{
                    background: {
                        color: {
                            value: "#000000",
                        },
                    },
                    particles: {
                        number: {
                            value: 40,
                            density: {
                                enable: true,
                                value_area: 1000,
                            },
                        },
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            enable: true,
                            distance: 150,
                            color: "#808080",
                            opacity: 0.3,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 1.5,
                        },
                    },
                }}
            />
            <div className="content">
                <h1 className="logo">Stark Industries</h1>
                <p className="tagline">AI Consulting</p>
            </div>
        </div>
    );
}

export default Stark;
